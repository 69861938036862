<template>
  <div>
    <!--begin::Dashboard-->
    <b-row>
      <b-col md="3" class="mt-3" v-for="(e,i) in events" :key="i">
        <b-card class="text-center">
          <b-card-text>
            <h6 class="mb-2">{{e.titre}}</h6>
            <b-img :src="e.img" fluid alt="Responsive image"></b-img>
            <Timer :dateEnd="e.date_fin"/>
          </b-card-text>
          <!-- <b-progress :value="e.remplicage" max="100" :variant="e.remplicage > 65 ? 'success' : 'danger'" show-progress animated height="1.5rem"></b-progress> -->
          <b-progress :value="(e.ca/e.target)*100" max="100" :variant="(e.ca/e.target)*100 > 65 ? 'success' : 'danger'" show-progress animated height="1.5rem"></b-progress>
          <h6 class="mt-2">{{e.ca}}K€ / {{e.target}}K€</h6>
        </b-card>
      </b-col>
    </b-row>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Timer from '@/view/layout/components/countdown/Timer'
export default {
  name: "dashboard",
  data(){
    return {
      events:[
        {
          "titre":"Enduro Magazine #55555",
          "img":"https://www.cppresse.fr/wp-content/uploads/2022/07/TABLETTE-EM-121_couv_boutique.jpg.webp",
          "date_fin":"2022-12-31",
          "remplicage": 50,
          "ca":60,
          "target":70
        },
        {
          "titre":"Sport Bikes #33",
          "img":"https://www.cppresse.fr/wp-content/uploads/2022/08/SPORTBIKES-134_couv_boutique.jpg.webp",
          "date_fin":"2022-11-03",
          "remplicage": 80,
          "ca":10,
          "target":7
        },
        {
          "titre":"Maisons à Vivre Campagne #1",
          "img":"https://www.cppresse.fr/wp-content/uploads/2022/07/couverture-MAVC-122_boutique.jpg.webp",
          "date_fin":"2022-10-15",
          "remplicage": 22,
          "ca":55,
          "target":100
        },
        {
          "titre":"Voyages à Moto #12",
          "img":"https://www.cppresse.fr/wp-content/uploads/2022/06/VAM16_Couv_boutique.jpg.webp",
          "date_fin":"2022-10-11",
          "remplicage": 95,
          "ca":12,
          "target":12
        },
        {
          "titre":"It Girl #11",
          "img":"https://www.cppresse.fr/wp-content/uploads/2021/12/ITGIRL_SUMMER22_boutique.jpg.webp",
          "date_fin":"2022-11-28",
          "remplicage": 100,
          "ca":25,
          "target":54
        },
        {
          "titre":"Fortnite Gaming #3",
          "img":"https://www.cppresse.fr/wp-content/uploads/2021/08/COUV-GAMING-FORTNITE-03_BD.jpg.webp",
          "date_fin":"2022-12-31",
          "remplicage": 55,
          "ca":23,
          "target":44
        },
        {
          "titre":"Icône – Johnny Hallyday",
          "img":"https://www.cppresse.fr/wp-content/uploads/2021/07/COUV_ICONE_JOHNNY-1.jpg.webp",
          "date_fin":"2021-12-25",
          "remplicage": 100,
          "ca":54,
          "target":12
        }
      ]
    }
  },
  components: {
    Timer
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
    * Set current active on click
    * @param event
    */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
